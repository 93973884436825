import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProductsSelection from "../components/ProductsSelection";

import { Container } from "@mui/material";

const ProductsPage = () => (
  <Layout>
    <Seo title="Primary Products & Services" />
    {/* Content */}

    <div className="bg-yellow-800">
      <Container maxWidth="xl" sx={{pt: 15, pb: 25}}>
        <h1 className="_h1 text-gray-800 text-center">
          Primary Products & Services
        </h1>

        <ProductsSelection />
      </Container>
    </div>
  </Layout>
);

export default ProductsPage;
